.ScrollLine {
    padding-top: 5vh;
    padding-bottom: 10vh;
    position: relative;
}

.firstLineTeacher {
    width: 100%;
    padding-top: 3rem;
    padding-bottom: 1.5rem;
}
.parallaxTeacher {
    overflow: hidden;
    /* letter-spacing: -2px; */
    line-height: 0.8;
    margin: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
}

.parallaxTeacher .scroller {
    display: flex;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    gap: 2rem;
    /* border:1px solid blue; */
}

.parallaxTeacher span {
    display: block;
    /* margin-right: 30px; */
}

.lineTeacher {
    display: flex;
    align-items: center;
    width: fit-content;
    /* border:1px solid red; */
    justify-content: space-between;
    gap: 4rem;
}
.lineTeacher p {
    margin: 0;
    padding: 0;
    margin-left: 1.6rem;
}
.img-container {
    width: 200px;
    height: 200px;
    /* background:turquoise; */
    border-radius: 24px 0px;
    overflow: hidden;
    /* box-shadow: 8px 16px 48px 0px rgba(17, 17, 26, 0.10),
          4px 8px 24px 0px rgba(17, 17, 26, 0.10),
            2px 1px 8px 0px rgba(17, 17, 26, 0.4); */
}

.img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
