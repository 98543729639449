
.TeacherScroll{
    padding-top: 8vh;
    padding-bottom: 10vh;
    position: relative;
}

.parallax{
      overflow: hidden;
      letter-spacing: -2px;
      box-sizing: border-box;
      line-height: 0.8;
      margin: 0;
      white-space: nowrap;
      display: flex;
      width:100%;
      /* border:1px solid purple; */
      flex-wrap: nowrap;
    }
    
  .firstLine {
    /* border:1px solid black; */
    width: 100%;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    background-color: var(--color-bassthalk);
  }
  
  
  .parallax .scroller{
    
    /* border:1px solid red; */
    font-weight: 600;
    text-transform: uppercase;
    font-size: 64px;
    display: flex;
    width:fit-content;
    white-space: nowrap;
    display: flex;
    gap: 4rem;
    flex-wrap: nowrap;
}


.parallax span{
  /* border:1px solid green; */
  display: block;
  margin-right: 1rem;
}

.parallax .lineContent{
  /* border:1px solid green; */
  display: flex; 
  align-items: center;
  width:fit-content;
  margin: 0;
  padding: 0;
  justify-content: space-between;
  gap: 4rem;
}

.parallax .lineContent p{
  margin:0; padding:0;
  margin-left: 1.6rem;
  font-family: Lama Sans;
  font-size: 32px;
  font-weight: 800;
  line-height: 32px; /* 100% */
  letter-spacing: 0.5px;
}

.parallax .lineContent .Teacher-mask{
  width: 10rem;
  height: 10rem;
  background-color: wheat;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;

}

.parallax .lineContent .Teacher-mask .image{
  width: 100%;
  height: 100%;
  background-size: cover;
  mask-image: radial-gradient(circle(10rem at center), transparent 50%, black 100%);
}
