.header-card {
    color: var(--primary-20, #064a60);
    text-align: center;
    font-family: Almarai;
    font-size: 36px;
    font-weight: 700;
    line-height: 34px; /* 94.444% */
    letter-spacing: 0.72px;
  }
  .desc {
    max-width: 240px;
  }
  .desc p {
    color: #000;
    text-align: center;
    font-family: Almarai;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px; /* 141.667% */
    letter-spacing: 0.48px;
  }
  